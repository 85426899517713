export const useAudioPlayer = () => {
  const url = useState<string | undefined>(() => undefined)
  const imageUrl = useState<string | undefined>(() => undefined)
  const title = useState<string | undefined>(() => undefined)
  const audioCurrentTime = useState<number | undefined>(() => undefined)

  const isPlaying = useState<boolean>(() => false)
  const isMuted = useState<boolean>(() => false)
  const isHidden = useState<boolean>(() => true)

  function play(options: { url: string; imageUrl?: string; title?: string }) {
    url.value = options.url
    imageUrl.value = options.imageUrl
    title.value = options.title
    isPlaying.value = true
    isHidden.value = false
  }
  function stop() {
    isPlaying.value = false
    isHidden.value = true
    url.value = undefined
    imageUrl.value = undefined
    title.value = undefined
    isMuted.value = false
  }
  watch(audioCurrentTime, (newValue) => {
    audioCurrentTime.value = newValue
  })
  return {
    url,
    imageUrl,
    title,
    isPlaying,
    isMuted,
    isHidden,
    audioCurrentTime,
    play,
    stop,
  }
}
